var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-table',{staticStyle:{"margin-top":"3%","border-top":"1px solid","border-left":"1px solid","color":"black"},attrs:{"data":_vm.tableRows,"max-height":"500px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:indextr === 0 ? 'fixed_first_row_section' : '',style:(tr.style)},[_c('vs-td',{staticClass:"fixed_columns_section",style:(tr.style)},[_vm._v(_vm._s(tr.overall))]),_c('vs-td',{staticClass:"fixed_header_level_section",style:(tr.style)},[_vm._v(_vm._s(tr.level))]),_c('vs-td',{style:([_vm.selected_city === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ])},[_c('div',{on:{"click":function($event){return _vm.countDeails(
              tr.level === '' ? tr.overall : tr.level,
              _vm.selected_city,
              'allocated'

            )}}},[_vm._v("\n            "+_vm._s(tr.city.allocated)+"\n          ")])]),_c('vs-td',{style:([_vm.selected_city === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ])},[_c('div',{on:{"click":function($event){return _vm.countDeails(
              tr.level === '' ? tr.overall : tr.level,
              _vm.selected_city,
              'delays'
            )}}},[_vm._v("\n            "+_vm._s(tr.city.delays)+"\n          ")])]),_vm._l((tr.dynamicSpoc),function(spoc,spocIndex){return _c('vs-td',{key:spocIndex,staticStyle:{"border":"1px solid"}},[_c('div',{on:{"click":function($event){return _vm.countDeails(
              tr.level === '' ? tr.overall : tr.level,
              [tr, spocIndex],
              data[0].dynamicSpoc[spocIndex]
            )}}},[_vm._v("\n            "+_vm._s(spoc)+"\n          ")])])})],2)})}}])},[_c('template',{slot:"thead"},_vm._l((_vm.tableHeaders),function(head,index){return _c('vs-th',{key:index,class:{ fixed_columns_section: head.header == 'OverAll', fixed_header_level_section: head.header == 'Level' },staticStyle:{"border-right":"1px solid","position":"sticky","top":"0px","background-color":"white"},attrs:{"colspan":head.colspan}},[_vm._v(_vm._s(head.header))])}),1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }