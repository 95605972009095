<template>
  <div>
    <vx-card actionable class="cardx" title="Delays(Level SR)">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <!-- <VueHotelDatepicker @confirm="getValue" /> -->
          <!-- <date-range-picker
          ref="picker"
          :opens="'right'"
          :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
          :minDate="null"
          :maxDate="null"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="dateRange"
          style="width: 240px"
          @update="updateValues"
          ></date-range-picker>-->
          <datepicker placeholder="Select Date" v-model="dateRange" style="z-index: 1000"></datepicker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5">
          <v-select label="level" :options="levels" v-model="selected_level" :clearable=false
            style="width: 180px; z-index: 1000"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <v-select v-if="getrole('cm4')" label="cities" :options="city_options" v-model="selected_city"
            style="width: 240px; z-index: 1000"></v-select>
          <!-- Old-->
          <!-- <v-select
            v-if="getrole('cm1') || getrole('ac1') || getrole('exed')"
            disabled
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>-->
          <!-- New-->
          <v-select v-if="getrole('cm1') || getrole('ac1') || getrole('exed')" disabled label="cities" :options="city_options"
            v-model="selected_city" style="width: 240px; z-index: 1000"></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <!-- <v-select
            v-if="getrole('cm4')"
            label="City classification"
            :options="city_classification_list"
            v-model="selected_city_classification"
            style="width: 240px; z-index: 1000"
          ></v-select>
          <v-select
            v-if="getrole('cm1') || getrole('ac1') || getrole('exed')"
            label="City classification"
            :options="city_classification_list"
            v-model="selected_city_classification"
            style="width: 240px; z-index: 1000"
          ></v-select> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-button color="dark" type="filled" @click="getSpocsWithCity()">GO</vs-button>
        </vs-col>
      </vs-row>
      <vs-table v-if="level_value == 'M Levels'" :data="tableRows" style="
            margin-top: 3%;
            border-top: 1px solid;
            border-left: 1px solid;
            color: black;
          " max-height="500px">
        <template slot="thead">
          <vs-th :key="index" v-for="(head, index) in tableHeaders" :colspan="head.colspan"
            style="border-right: 1px solid; position: sticky;top: 0px; background-color: white;"
            :class="{ fixed_sr_columns_section: head.header == 'OverAll', fixed_sr_header_level_section: head.header == 'Level' }">{{ head.header }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style" :class="indextr === 0 ? 'fixed_sr_first_row_section' : ''">
            <vs-td class="fixed_sr_columns_section" :style="tr.style">{{ tr.overall }}</vs-td>
            <vs-td class="fixed_sr_header_level_section" :style="tr.style">{{ tr.level }}</vs-td>
            <vs-td :style="[citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="
                preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  selected_city,
                  'allocated'
                )
              ">
                {{ tr.city.allocated }}
              </div>
            </vs-td>
            <!-- <vs-td style="border: 1px solid">
            <div
              @click="preFlightFetch(tr.level === '' ? tr.overall : tr.level, selected_city, 'contacted')"
            >{{tr.city.contacted}}</div>
            </vs-td>-->
            <vs-td :style="[citySelection === 'Pan India' ? {'border': 'none'} : {'border': '1px solid'} ]">
              <div @click="
                preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  selected_city,
                  'delays'
                )
              ">
                {{ tr.city.delays }}
              </div>
            </vs-td>
            <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" style="border: 1px solid">
              <div @click="
                preFlightFetch(
                  tr.level === '' ? tr.overall : tr.level,
                  [tr, spocIndex],
                  data[0].dynamicSpoc[spocIndex]
                )
              ">
                {{ spoc }}
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!-- SR U levels -->
      <DelaysBySRULevels v-if="level_value == 'U Levels'"></DelaysBySRULevels>
    </vx-card>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import VueApexCharts from "vue-apexcharts";
import EventBus from "../components/eventbus";
import DelaysBySRULevels from "./DelaysBySRULevels.vue";
export default {
  data() {
    return {
      citySelection: "",
      levels: ["M Levels", "U Levels"],
      level_value: "M Levels",
      selected_level: "M Levels",
      selected_spocs: [],
      pieChart: {
        series: [],
        chartOptions: {
          legend: {
            show: false,
          },
          labels: [],
          colors: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      name: "App",
      type: "margin",
      fromDate: null,
      toDate: null,
      spoc_names: [],
      select1: "",
      select2: "",
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      dateRange: new Date(),
      duration: [
        { text: "WTD", value: 0 },
        { text: "MTD", value: 1 },
        { text: "QTD", value: 2 },
        { text: "YTD", value: 3 },
      ],
      sort_by: [
        { text: "MWB Count", value: 0 },
        { text: "Enrollment", value: 1 },
        { text: "Visitors", value: 2 },
        { text: "Utilization", value: 3 },
        { text: "Delays", value: 4 },
        { text: "Due", value: 5 },
        { text: "SR%", value: 6 },
      ],
      selected_city: "",
      loggedin: "",
      city_options: [
          "Ahmedabad",
          "Bangalore",
          "Chennai",
          "Delhi",
          "Ernakulam",
          "Hyderabad",
          "Kolkata",
          "Mumbai",
          "Pune",
      ],
      cities: [],
      tablerowConstant: [
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid",background: "#ffffff" },
          overall: "",
          level: "",
        },
        {
          style: { background: "#f6d094" },
          overall: "M7",
          level: "M7",
          rowspan: 14,
          type: "M7",
        },
        { style: { background: "#f6d094" }, level: "M7#", type: "M7" },
        { style: { background: "#f6d094" }, level: "M7+", type: "M7" },
        { style: { background: "#f6d094" }, level: "M7-", type: "M7" },
        { style: { background: "#f6d094" }, level: "M7-JJ", type: "M7" },
        { style: { background: "#f6d094" }, level: "M7D1", type: "M7" },
        { style: { background: "#f6d094" }, level: "M7D2", type: "M7" },
        { style: { background: "#f6d094" }, level: "M7X", type: "M7" },
        { style: { background: "#f6d094" }, level: "M8", type: "M7" },
        { style: { background: "#f6d094" }, level: "M8+", type: "M7" },
        { style: { background: "#f6d094" }, level: "M8-", type: "M7" },
        { style: { background: "#f6d094" }, level: "M9", type: "M7" },
        { style: { background: "#f6d094" }, level: "M9-", type: "M7" },
        { style: { background: "#f6d094" }, level: "M10", type: "M7" },
        {
          style: { borderBottom: "1px solid", background: "#f6d094" },
          overall: "Total",
          level: "Enrolled_total",
          type: "M7 Total",
        },
        {
          style: { background: "#ccffcc" },
          level: "M3",
          type: "P1",
        },
        { style: { background: "#ccffcc" }, level: "M3+", type: "P1" },

        {
          style: { background: "#ccffcc" },
          overall: "P1",
          level: "M5",
          type: "P1",
        },
        {
          style: { background: "#ccffcc" },
          level: "M6",
          rowspan: 4,
          type: "P1",
        },

        {
          style: { borderBottom: "1px solid", background: "#ccffcc" },
          overall: "Total",
          level: "P1",
          type: "P1 Total",
        },

        // {
        //   style: { background: "#ffffcc" },
        //   level: "L6",
        //   rowspan: 6,
        //   type: "P2"
        // },
        { style: { background: "#ffffcc" }, overall: "P2", level: "M4", type: "P2" },
        // {
        //   style: { background: "#ffffcc" },
        //   overall: "P2",
        //   level: "L4",
        //   type: "P2"
        // },
        { style: { background: "#ffffcc" }, level: "M2", type: "P2" },
        // { style: { background: "#ffffcc" }, level: "L3", type: "P2" },
        // { style: { background: "#ffffcc" }, level: "L2", type: "P2" },
        {
          style: { borderBottom: "1px solid", background: "#ffffcc" },
          overall: "Total",
          level: "P2",
          type: "P2 Total"
        },
        // {
        //   style: { background: "#ccffff", borderTop: "1px solid" },
        //   level: "L4-",
        //   rowspan: 2,
        //   type: "P3"
        // },
        // { style: { background: "#ccffff" }, level: "L1", type: "P3" },
        {
          style: { background: "#ccffff" },
          overall: "P3",
          level: "M4-",
          rowspan: 4,
          type: "P3",
        },
        // { style: { background: "#ccffff" }, level: "M4", type: "P3" },
        // { style: { background: "#ccffff" }, level: "M2", type: "P3" },
        { style: { background: "#ccffff" }, level: "M1", type: "P3" },
        {
          style: { borderBottom: "1px solid", background: "#ccffff" },
          overall: "Total",
          level: "P3",
          type: "P3 Total",
        },
        
        //  { style: { background: "#ccffff" }, level: "L2-" },
        // {
        //   style: { background: "#f6d094", border: "1px solid" },
        //   overall: "M7",
        //   level: "",
        // },
        // {
        //   style: { background: "#cccccc", border: "1px solid" },
        //   overall: "N/A",
        //   level: ""
        // }
        
        {
          style: { borderBottom: "1px solid", background: "#FFCCCC" },
          overall: "Grand Total",
          level: "",
        },
      ],
      // tablerowConstant: [
      //   {
      //     style: { borderTop: "1px solid", borderBottom: "1px solid" },
      //     overall: "",
      //     level: "",
      //   },
      //   {
      //     style: { borderBottom: "1px solid", background: "#FFCCCC" },
      //     overall: "Total",
      //     level: "",
      //   },
      //   {
      //     style: { background: "#ccffcc" },
      //     level: "M6",
      //     rowspan: 4,
      //     type: "P1",
      //   },
      //   {
      //     style: { background: "#ccffcc" },
      //     overall: "P1",
      //     level: "M5",
      //     type: "P1",
      //   },
      //   { style: { background: "#ccffcc" }, level: "M3+", type: "P1" },
      //   {
      //     style: { background: "#ccffcc" },
      //     level: "M3",
      //     type: "P1",
      //   },
      //   {
      //     style: { borderBottom: "1px solid", background: "#ccffcc" },
      //     overall: "Total",
      //     level: "",
      //     type: "P1 Total",
      //   },
      //   // {
      //   //   style: { background: "#ffffcc" },
      //   //   level: "L6",
      //   //   rowspan: 6,
      //   //   type: "P2"
      //   // },
      //   // { style: { background: "#ffffcc" }, level: "L5", type: "P2" },
      //   // {
      //   //   style: { background: "#ffffcc" },
      //   //   overall: "P2",
      //   //   level: "L4",
      //   //   type: "P2"
      //   // },
      //   // { style: { background: "#ffffcc" }, level: "L3+", type: "P2" },
      //   // { style: { background: "#ffffcc" }, level: "L3", type: "P2" },
      //   // { style: { background: "#ffffcc" }, level: "L2", type: "P2" },
      //   // {
      //   //   style: { borderBottom: "1px solid", background: "#ffffcc" },
      //   //   overall: "Total",
      //   //   level: "",
      //   //   type: "P2 Total"
      //   // },
      //   // {
      //   //   style: { background: "#ccffff", borderTop: "1px solid" },
      //   //   level: "L4-",
      //   //   rowspan: 2,
      //   //   type: "P3"
      //   // },
      //   // { style: { background: "#ccffff" }, level: "L1", type: "P3" },
      //   {
      //     style: { background: "#ccffff" },
      //     overall: "P3",
      //     level: "M4",
      //     rowspan: 4,
      //     type: "P3",
      //   },
      //   { style: { background: "#ccffff" }, level: "M4-", type: "P3" },
      //   { style: { background: "#ccffff" }, level: "M2", type: "P3" },
      //   { style: { background: "#ccffff" }, level: "M1", type: "P3" },
      //   {
      //     style: { borderBottom: "1px solid", background: "#ccffff" },
      //     overall: "Total",
      //     level: "",
      //     type: "P3 Total",
      //   },

      //   //  { style: { background: "#ccffff" }, level: "L2-" },
      //   // {
      //   //   style: { background: "#f6d094", border: "1px solid" },
      //   //   overall: "M7",
      //   //   level: "",
      //   // },
      //   // {
      //   //   style: { background: "#cccccc", border: "1px solid" },
      //   //   overall: "N/A",
      //   //   level: ""
      //   // }
      //   {
      //     style: { background: "#f6d094" },
      //     overall: "M7",
      //     level: "M10",
      //     rowspan: 14,
      //     type: "M7",
      //   },
      //   { style: { background: "#f6d094" }, level: "M7", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7#", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7+", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7-", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7-JJ", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7D1", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7D2", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M7X", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M8", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M8+", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M8-", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M9", type: "M7" },
      //   { style: { background: "#f6d094" }, level: "M9-", type: "M7" },
      //   {
      //     style: { borderBottom: "1px solid", background: "#f6d094" },
      //     overall: "Total",
      //     level: "",
      //     type: "M7 Total",
      //   },
      // ],
      tableHeaders: [],
      tableRows: [],
      users: [
        {
          id: 1,
          name: "Ismail",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 2,
          name: "Nida",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 3,
          name: "Anita",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 4,
          name: "Imran",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
        {
          id: 5,
          name: "Jain",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-",
        },
      ],
    };
  },
  mounted() {
    // console.log("Hrrlo");
    // this.getSpocCities();
    this.getSpocs();
    EventBus.$on("updateCoverage", () => {
      this.updateValues();
    });
  },
  watch: {
    // selected_city_classification(val){
    //   this.selected_city_classification = val;
    // },
    selected_level(val){
      if (val == 'U Levels') {
        this.selected_city = this.cities[1];
        this.getSpocsWithCity();
      }
    },
    selected_city(val) {
      this.selected_city = val;
    },
    dateRange() {
      // this.updateValues();
    },
  },
  methods: {
    // getSpocCities() {
    //   let url = `${constants.SERVER_API}getSpocCities`;
    //   axios
    //     .get(url, {
    //       headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
    //     })
    //     .then((response) => {
    //       console.log("dataaa------", response);
    //       this.cities = response.data;
    //       console.log("getSpocCities", this.cities);
    //       if (localStorage.getItem("role") === "cm4") {
    //         this.selected_city = this.cities[0];
    //       } else if (
    //         localStorage.getItem("role") === "cm1" ||
    //         localStorage.getItem("role") === "ac1" ||
    //         localStorage.getItem("role") === "exed"
    //       ) {
    //         this.spoc_names.forEach((spoc) => {
    //           if (spoc.id === this.loggedin) {
    //             console.log("assigning the city");
    //             this.selected_city = spoc.city;
    //           }
    //         });
    //       }
    //       // this.extractSpocByCity(this.cities);
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    getSpocsWithCity() {
      // let city = this.new_selected_city;
      console.log(this.selected_city);

      let url = `${constants.SERVER_API}getSpocsWithCity?city=${this.selected_city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getSpocsWithCity", response);
          this.selected_spocs = response.data.spocs;
          console.log("step1", this.selected_spocs);

          // this.getSpocIds(spoc_data);
          this.level_value = this.selected_level;
          this.citySelection = this.selected_city;
          this.updateValues();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocIds(spoc_data) {
      let spoc_list = spoc_data;
      for (let i = 0; i < spoc_list.length; i++) {
        const element = spoc_list[i];
        this.selected_spocs.push(element.id);
      }
      this.selected_spocs = this.selected_spocs.join();
    },

    getrole(providedrole) {
      if (localStorage.getItem("role") === providedrole) {
        return true;
      } else {
        return false;
      }
    },
    preFlightFetch(level, placement, type) {
      console.log("preFlightFetch placement", level, placement, type);
      if (type === "Allocated" || type === "Delays") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        this.fetchSelectedData(level, spocname, newtype);
      } else {
        this.fetchSelectedData(level, placement, type);
      }
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 2;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: [],
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 2) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 2) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
    convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    getstyle(data) {
      if (
        data.overall.contacted === "Total" ||
        data.level === "M3" ||
        data.level === "L6" ||
        data.level === "L1" ||
        data.level === "M4"
        // data.overall.contacted === "N/A"
      ) {
        return "1px solid";
      } else {
        return "";
      }
    },
    fetchSelectedData(level, placement, type) {
      let popupName = `${level} - ${placement.toUpperCase()} - ${type.toUpperCase()}`;
      this.selected_spocs.forEach((spoc) => {
        console.log("spoc fetchSelectedData", spoc);
        if (spoc.full_name === placement) {
          placement = spoc.id;
        }
      });
      if (this.dateRange === "") {
        this.$vs.notify({
          title: "Select a Date",
          text: "Select a date to continue using this feature",
          color: "warning",
        });
      } else {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getSrDelaysByLevelContactDetails`;
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          level: level,
          type: type,
          spoc_id: "",
          city: "",
          selected_level_type: 'm_level',
        };
        if (obj.level == 'Grand Total') {
          obj.level = "total";
        }
        if (Number.isInteger(placement)) {
          obj.spoc_id = placement;
        } else {
          obj.city = placement;
        }
        if (obj.level === "Total") {
          obj.level = "total";
        }
        axios
          .post(url, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            this.$vs.loading.close();
            let popupcount = response.data.total;
            console.log("counnt", popupcount);
            EventBus.$emit("open-coverage-details", [
              obj,
              url,
              response.data,
              "getSrDelaysByLevelContactDetails",
              popupName + " - " + popupcount,
            ]);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    getSpocList(selected_spocs) {
      console.log("selected spocs list step 1", selected_spocs);
      let selected_spocs_list = [];
      selected_spocs.forEach((spoc) => {
        selected_spocs_list.push(spoc.id);
      });
      console.log("selected spocs list step 2", selected_spocs_list);
      selected_spocs_list = selected_spocs_list.join();
      return selected_spocs_list;
    },
    updateValues() {
      console.log("this.selected_spocs-------", this.selected_spocs);
      this.$vs.loading();
      let level_obj = {
        "M Levels": "m_level",
        "U Levels": "u_level"
      }
      let obj = {
        selected_level_type: level_obj[this.selected_level],
        selected_date: this.datatoTimestamp(this.dateRange),
        spoc_ids: this.getSpocList(this.selected_spocs),
        team: "SR",
        pan_india: "",
      };
      if (this.selected_city == 'Pan India') {
        obj.pan_india = 'Yes';
      }
      console.log("updateValues dateRange", obj);
      // let url = "https://milescmback.2x2.ninja/api/getDelaysByLevelTeam";
      let url =`${constants.SERVER_API}getDelaysByLevelTeam`;

      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("step2", response);
          // this.consoleLogTotals(response.data.data);
          if (response.data.status === "error") {
            this.handleNotification(response);
            this.$vs.loading.close();
          } else {
            this.generateDataTable(response.data.data);
            // console.log("dastf", this.generateDataTable(response.data.data));
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    generateDataTable(response) {
      if (this.selected_level == "M Levels") {
        this.tableRows = this.tablerowConstant;
        this.generateTableHeaders(response);
        this.generateTableRows(response);
      } else if (this.selected_level == "U Levels") {
        EventBus.$emit("get-SRulevel-data", response, this.selected_city, this.selected_spocs);
      }
    },
    generateTableHeaders(response) {
      // console.log("asd", spoc_names);
      console.log("dealys", response);
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "OverAll",
        colspan: 1,
        fixed: true,
      });
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
        fixed: true,
      });

      // let city = this.selected_city;
      // this.tableHeaders.push({
      //   header: city,
      //   colspan: 2,
      // });
      // response.forEach((cityData) => {
      //   let obj = {
      //     header: cityData.spoc_name,
      //     colspan: 2,
      //   };
      //   this.tableHeaders.push(obj);
      // });
      if(this.selected_city != 'Pan India'){
        let city = this.selected_city;
        this.tableHeaders.push({
          header: city,
          colspan: 2,
        });
      }else {
        this.tableHeaders.push({
          header: '',
          colspan: 2,
        });
      }
      if (this.selected_city == "Pan India") {
        response.forEach((cityData) => {
        let obj = {
          header: cityData.city,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
      }else{
      response.forEach((cityData) => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 2,
        };
        this.tableHeaders.push(obj);
      });
    }
    },
    generateTableRows(response) {
      this.tableRows[0].dynamicSpoc = [];
      // this.tableRows[0].city = {
      //   allocated: "Allocated",
      //   delays: "Delays",
      // };
      // this.tableRows.forEach((row, index) => {
      //   if (index !== 0) {
      //     row.dynamicSpoc = [];
      //     row.city = {
      //       allocated: 0,
      //       delays: 0,
      //     };
      //   }
      // });
      if(this.selected_city != 'Pan India'){
      this.tableRows[0].city = {
        allocated: "Allocated",
        delays: "Delays",
      };
      }else{
        this.tableRows[0].city = {}
      }
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          if(this.selected_city != 'Pan India'){
          row.city = {
            allocated: 0,
            delays: 0,
          };
        }
        }
      });
      response.forEach(() => {
        // let allocated = 0;
        // let delays = 0;
        this.tableRows[0].dynamicSpoc.push("Allocated");
        this.tableRows[0].dynamicSpoc.push("Delays");
        // this.tableRows.forEach((row, index) => {
        //   if (index !== 0) {
        //     row.dynamicSpoc.push(allocated);
        //     row.dynamicSpoc.push(delays);
        //   }
        // });
      });
      this.InitialCalculation(response);
    },
    InitialCalculation(response) {
      this.tableRows.forEach((row, index) => {
        if (index > 0 && index !== this.tableRows.length - 1) {
          let cityallocated = 0;
          // let citycontacted = 0;
          let citydelays = 0;
          row.dynamicSpoc = [];
          response.forEach((spoc) => {
            for (var key in spoc) {
              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  for (var key2 in spoc[key]) {
                    if (spoc[key].hasOwnProperty(key2)) {
                      if (key2 === row.level) {
                        cityallocated =
                          cityallocated + spoc[key][key2].allocated;
                        citydelays = citydelays + spoc[key][key2].delays;
                        row.dynamicSpoc.push(spoc[key][key2].allocated);
                        row.dynamicSpoc.push(spoc[key][key2].delays);
                      }
                    }
                  }
                }
              }
            }
          });
          if(this.selected_city != 'Pan India'){
          row.city.allocated = cityallocated;
          row.city.delays = citydelays;
          }
        }
      });
      // let totalcityallocated = 0;
      // let totalcitydelays = 0;
      // let dynamicarr = [];
      // let naallocated = 0;
      // let nadelays = 0;
      // let nadynamic = [];
      // response.forEach((spoc) => {
      //   naallocated = naallocated + spoc.M7.allocated;
      //   nadelays = nadelays + spoc.M7.delays;
      //   nadynamic.push(spoc.M7.allocated);
      //   nadynamic.push(spoc.M7.delays);
      // });
      // this.tableRows[this.tableRows.length - 1].city.allocated = naallocated;
      // this.tableRows[this.tableRows.length - 1].city.delays = nadelays;
      // this.tableRows[this.tableRows.length - 1].dynamicSpoc = nadynamic;
      // this.tableRows.forEach((row, index) => {
      //   if (index === 1) {
      //     dynamicarr = row.dynamicSpoc;
      //   } else if (index > 1) {
      //     totalcityallocated = totalcityallocated + row.city.allocated;
      //     totalcitydelays = totalcitydelays + row.city.delays;
      //     // totalcityallocated = row.city.allocated;
      //     // totalcitydelays = row.city.delays;
      //     // console.log(row.dynamicSpoc);
      //     row.dynamicSpoc.forEach((spocStat, index2) => {
      //       // dynamicarr[index2] =
      //       //   parseInt(dynamicarr[index2]) + parseInt(spocStat);
      //       dynamicarr[index2] = parseInt(spocStat);
      //     });
      //   }
      // });
      // console.log(
      //   "Total InitialCalculation",
      //   totalcityallocated,
      //   totalcitydelays,
      //   dynamicarr
      // );
      // this.tableRows[1].city.allocated = totalcityallocated;
      // this.tableRows[1].city.delays = totalcitydelays;
      // this.tableRows[1].dynamicSpoc = dynamicarr;
      // this.calculateM7(response);
      // this.calculateM7();
      this.calculateTotals();
      this.$vs.loading.close();
    },
    calculateM7() {
      let totalcityallocated = 0;
      let totalcitydelays = 0;
      let dynamicarr = [];
      // let naallocated = 0;
      // let nadelays = 0;
      // let nadynamic = [];
      //  console.log("response",response);
      // response.forEach((spoc) => {
      //   naallocated = naallocated + spoc.M7.allocated;
      //   nadelays = nadelays + spoc.M7.delays;
      //   nadynamic.push(spoc.M7.allocated);
      //   nadynamic.push(spoc.M7.delays);
      // });
      // console.log("nadynamic M7",nadynamic);
      // this.tableRows[this.tableRows.length - 1].city.allocated = naallocated;
      // this.tableRows[this.tableRows.length - 1].city.delays = nadelays;
      // this.tableRows[this.tableRows.length - 1].dynamicSpoc = nadynamic;
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          totalcityallocated = totalcityallocated + row.city.allocated;
          totalcitydelays = totalcitydelays + row.city.delays;

          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] =
              parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].city.allocated = totalcityallocated;
      this.tableRows[1].city.delays = totalcitydelays;
      this.tableRows[1].dynamicSpoc = dynamicarr;
      console.log("Table rows", this.tableRows);
    },
    calculateTotals() {
      let parts = ["P1", "P2", "P3", "M7"];
      parts.forEach((eachPart) => {
        let cityAllocated = 0;
        let cityDelays = 0;
        let dynamicSpocValues = [];
        this.tableRows[0].dynamicSpoc.forEach(() => {
          dynamicSpocValues.push(0);
        });
        this.tableRows.forEach((tableRow) => {
          if(this.selected_city != 'Pan India'){
          if (tableRow.type === eachPart) {
            cityAllocated =
              parseInt(cityAllocated) + parseInt(tableRow.city.allocated);
            cityDelays = parseInt(cityDelays) + parseInt(tableRow.city.delays);
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          } else if (tableRow.type === `${eachPart} Total`) {
            tableRow.city.allocated = cityAllocated;
            tableRow.city.delays = cityDelays;
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
          }else{
            tableRow.city = {}
            if (tableRow.type === eachPart) {
              tableRow.dynamicSpoc.forEach((value, index) => {
                dynamicSpocValues[index] =
                  parseInt(dynamicSpocValues[index]) + parseInt(value);
              });
            }
            if (tableRow.type === `${eachPart} Total`) {
              tableRow.dynamicSpoc = dynamicSpocValues;
            }
          }
        });
      });
      this.tableRows.forEach(tableRow => {
        if (tableRow.overall === "Grand Total") {
         this.tableRows[15].dynamicSpoc.forEach((m7, m7_index) => {
          this.tableRows[20].dynamicSpoc.forEach((p1, p1_index) => {
            this.tableRows[23].dynamicSpoc.forEach((p2, p2_index) => {
              this.tableRows[26].dynamicSpoc.forEach((p3, p3_index) => {
              if (p1_index === p2_index && p2_index === p3_index && p3_index === m7_index) {
                tableRow.dynamicSpoc.push(p1 + p2 + p3 + m7);
              }
            })
            })
            });
          });
          if(this.selected_city != 'Pan India'){
          this.tableRows[27].city.allocated = this.tableRows[15].city.allocated + this.tableRows[20].city.allocated + this.tableRows[23].city.allocated + this.tableRows[26].city.allocated;
          this.tableRows[27].city.delays = this.tableRows[15].city.delays + this.tableRows[20].city.delays + this.tableRows[23].city.delays + this.tableRows[26].city.delays;
          }
      }
      });
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    getSpocs() {
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;
      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "cm4"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("get spocs response", response);
          this.loggedin = response.data.logged_in_user_id;
          this.spoc_names = response.data.spocs;
          // Uncommented this
          this.extractSpocByCity(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.city_options = ["Pan India"].concat(this.sortArrayAlphabetically(this.city_options));
      // this.city_classification_list = this.sortArrayAlphabetically(this.city_classification_list);
      if (localStorage.getItem("role") === "cm4") {
        this.selected_city = this.city_options[1];
        this.getSpocsWithCity();
      } else if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "exed"
      ) {
        this.spoc_names.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            console.log("assigning the city");
            this.selected_city = spoc.city;
          }
        });
      }
      console.log("hie---");
      // this.getCityClasificationCodes(this.selected_city);
      // this.updateValues();
    },
  },
  components: {
    DateRangePicker,
    Datepicker,
    VueApexCharts,
    "v-select": vSelect,
    DelaysBySRULevels
  },
};
</script>
<style scoped>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
.fixed_sr_columns_section {
  left: 0px;
  position: sticky;
  z-index: 100;
  background: white;
}

.fixed_sr_header_level_section {
  left: 60px;
  position: sticky;
  z-index: 100;
  width: 100px;
  /* background: white; */
  /* border: 1px solid black; */
}
.fixed_sr_first_row_section {
  position: sticky;
  top: 45px;
  background-color: white;
  z-index: 100000;
}
</style>